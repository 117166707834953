import snsWebSdk from '@sumsub/websdk'
import { useVerificationStore } from '../stores/useVerificationStore'
import type { UserVerificationTypeCreate } from '../components/types'

export function useSumSubVerification() {
  const stFetch = useRawStFetch()
  const { locale } = useI18n()
  const verificationStore = useVerificationStore()
  const { userVerification, activeLevel } = storeToRefs(verificationStore)
  const { fetchUserVerification, createUserVerification } = verificationStore

  async function getNewAccessToken() {
    if (!userVerification.value?.id) {
      console.error(
        'Failed to get verification token without userVerification id',
      )
      return ''
    }

    const { data, error } = await stFetch('/user-verification/token/get', {
      method: 'post',
      body: {
        verificationId: userVerification.value.id,
      },
    })

    if (error) {
      console.error('Failed to get verification token', error)
      return ''
    }

    return data.token
  }

  async function launchSumSubWebSdk(
    container: string | HTMLElement | Ref<HTMLElement | undefined>,
  ) {
    const snsWebSdkInstance = snsWebSdk
      .init(
        await getNewAccessToken(),
        /* 
          token update callback, must return Promise
          Access token expired
          get a new one and pass it to the callback to re-initiate the WebSDK
        */
        () => getNewAccessToken(),
      )
      .withConf({
        lang: locale.value,
        theme: 'dark',
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.onStepCompleted', (payload) => {
        console.info('onStepCompleted', payload)
        fetchUserVerification()
      })
      .on('idCheck.onError', (error) => {
        console.error('idCheck error', error)
      })
      .build()

    // launch the WebSDK by providing the container element for it
    const containerElement = toValue(container)
    if (containerElement) {
      snsWebSdkInstance.launch(containerElement)
    } else {
      console.error('Wrong container for WebSDK')
    }
  }

  async function startVerification(
    container: string | HTMLElement | Ref<HTMLElement | undefined>,
  ) {
    if (userVerification.value) {
      await launchSumSubWebSdk(container)
    } else {
      const levelsMap: Record<number, UserVerificationTypeCreate> = {
        2: 'level2',
        3: 'actionSourceOfFunds',
      }
      await createUserVerification(levelsMap[activeLevel.value])
      await launchSumSubWebSdk(container)
    }
  }

  return {
    startVerification,
  }
}
